import { createI18n } from "vue-i18n";
import In from "./in_IN";
import en from "./en_US";

const i18n = createI18n({
  legacy: false,
  locale: "In",
  messages: {
    In: In,
    en: en,
  },
});

export default i18n;
