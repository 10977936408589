import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { NoticeBar, Swipe, SwipeItem, Popup } from "vant";
import "vant/lib/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import VueLuckyCanvas from "@lucky-canvas/vue";
import i18n from "./lang/index";
import "amfe-flexible";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(ElementPlus, { size: "large" })
  .use(NoticeBar)
  .use(Swipe)
  .use(SwipeItem)
  .use(Popup)
  .use(VueLuckyCanvas)
  .mount("#app");
