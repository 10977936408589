import axios from "axios";
import { defaultUrl } from "@/request/baseUrl";
import { ElMessage } from "element-plus";

//创建axios实例
const service = axios.create({
  baseURL: defaultUrl,
  timeout: 5000,
  headers: {
    //编译语言
    "Content-type": "application/json;charset=utf-8",
  },
});
//请求拦截
service.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};
    if (localStorage.getItem("token")) {
      config.headers.authorization = localStorage.getItem("token") || "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//响应拦截：后端返回来的结果
service.interceptors.response.use(
  (res) => {
    const code = res.data.code;
    if (code !== 0) {
      //请求失败（包括token失效，302，404...根据和后端约定好的状态码做出不同的处理）
      switch (code) {
        case 401:
          localStorage.removeItem("navChange");
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          ElMessage.warning("登录过期，请重新登陆");
          break;
        case 500:
          console.log("服务端错误");
          break;
      }
      return Promise.reject(res);
    } else {
      //请求成功
      return Promise.resolve(res.data);
    }
  },
  (err) => {
    console.log(err, "错误信息的处理");
    //处理错误响应
    return Promise.reject(err);
  }
);
export default service;
