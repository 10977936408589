<template>
  <div class="footer">
    <div class="f_list">
      <h3>Exclusive</h3>
      <p>Subscribe</p>
      <p>Get 10% off your fist order</p>
      <el-input
        size="default"
        v-model="indexSearch"
        style="max-width: 200px"
        placeholder="Enter your email"
        class="inputSearch"
      >
        <template #append>
          <el-button class="sbut" :icon="Message" />
        </template>
      </el-input>
    </div>
    <div class="f_list">
      <h3>Account</h3>
      <p @click="gotoPage('/User', 0)">My Account</p>
      <p @click="gotoPage('/ShoppingCart', 4)">Cart</p>
      <p @click="gotoPage('/Collect', 3)">Wishlist</p>
    </div>
    <div class="f_list">
      <h3>Quick Link</h3>
      <p>privacy policy</p>
      <p>Terms Of Use</p>
      <p>FAQ</p>
      <p>Countact</p>
    </div>
    <div class="f_list">
      <h3>Exclusive</h3>
      <p>Subscribe</p>
      <p>Get 10% off your fist order</p>
    </div>
    <p class="footer_js">@ Copyright Rimel 2024. All right reserved</p>
  </div>
</template>

<script setup lang="js">
import {ref,reactive,onMounted}from "vue"
import { Message } from '@element-plus/icons-vue';
import { useRouter } from "vue-router";
const router = useRouter()
const gotoPage = (url,i)=>{
  localStorage.setItem('navChange',i)
  router.push(url)
}
</script>
<style lang="scss">
.footer {
  width: 100%;
  height: 270px;
  background-color: #d1d1d3;
  padding: 30px 100px;
  display: flex;
  justify-content: space-around;
  position: relative;
  font-size: 20px;
  .f_list {
    h3 {
      font-size: 24px;
    }
    p {
      margin: 12px 0;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .footer_js {
    position: absolute;
    bottom: 10px;
    font-size: 13px;
    color: rgba($color: #000, $alpha: 0.5);
  }
}
.inputSearch {
  height: 40px;
}
</style>
