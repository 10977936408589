<template>
  <head class="mobelNav">
    <el-icon @click="navChange" v-if="expand"><Expand /></el-icon>
    <el-icon @click="navChange" v-else><Fold /></el-icon>
    <div class="m_login" v-if="isLogin">
      <p class="loginBtn" @click="login">登录</p>
      <p class="regBtn" @click="register">注册</p>
    </div>
  </head>
  <head class="nav">
    <!-- <img class="logo" src="" alt="" /> -->
    <p class="logo">Exclusive logo</p>
    <div class="nav_box">
      <p
        @click="navigator(1)"
        class="nav_item"
        :class="activeNav == 1 ? 'activeNav' : ''"
      >
        Home
      </p>
      <p
        @click="navigator(2)"
        class="nav_item"
        :class="activeNav == 2 ? 'activeNav' : ''"
      >
        盲盒
      </p>
    </div>

    <div class="nav_icon">
      <div class="searchInput">
        <el-input
          v-model="indexSearch"
          style="max-width: 300px"
          size="small"
          placeholder="What are you looking for?"
          class="inputSearch"
        >
          <template #append>
            <el-button class="sbut" @click="searchFun" :icon="Search" />
          </template>
        </el-input>
      </div>
      <el-icon @click="gotoPage('/Collect', 3)" color="#fff"><Star /></el-icon>
      <el-icon @click="gotoPage('/ShoppingCart', 4)" color="#fff"
        ><ShoppingCart
      /></el-icon>
      <div class="lang">
        <p>IN</p>
        <img src="/img/image/IN.png" alt="" />
        <el-icon color="#fff"><ArrowDown /></el-icon>
      </div>
      <div class="login" v-if="isLogin">
        <el-button size="small" color="#FFD600" type="warning" @click="login"
          >登录</el-button
        >
        <el-button size="small" color="#676eff" @click="register" plain
          >注册</el-button
        >
      </div>
      <el-dropdown>
        <span class="el-dropdown-link">
          <div class="avater" v-if="!isLogin">
            <el-avatar
              :size="30"
              src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
            />
            <p>{{ userName }}</p>
          </div>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="gotoUser">个人中心</el-dropdown-item>
            <el-dropdown-item @click="outLogin">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </head>
  <van-popup position="left" v-model:show="show" @close="navClose">
    <div class="m_n_Content">
      <div class="m_avater" v-if="!isLogin">
        <el-avatar
          :size="30"
          src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
        />
        <p>{{ userName }}</p>
      </div>
      <div class="m_navLink">
        <p @click="navigator(1)">Home</p>
        <p @click="navigator(2)">盲盒</p>
        <p @click="gotoPage('/Collect', 3)">收藏</p>
        <p @click="gotoPage('/ShoppingCart', 4)">购物车</p>
        <p @click="gotoUser">个人中心</p>
        <p @click="mNavTo()">语言</p>
        <p v-if="!isLogin" @click="outLogin">退出登录</p>
      </div>
    </div>
  </van-popup>
</template>

<script setup lang="js">
import { Popup } from 'vant';
import { ElMessage  } from "element-plus";
import { Search,Star,ShoppingCart,ArrowDown,Expand,Fold } from '@element-plus/icons-vue';
import {ref,relative,onMounted } from 'vue'
import {logoutApi} from "@/request/api"
import { useRouter } from "vue-router";
const isLogin = ref(true)
const userName = ref('')
const router = useRouter()
const indexSearch = ref('')
const expand = ref(true)
const show = ref(false)
const navChange = ()=>{
  show.value = !show.value
  expand.value = !expand.value
}
const navClose = ()=>{
  show.value = false
  expand.value = true
}
const activeNav = ref(localStorage.getItem('navChange') || 1)
const navigator = (i) => {
  if(i == 1){
    activeNav.value = i
  localStorage.setItem('navChange',i)
    router.push('/')

  }else{
    if(!localStorage.getItem('token')){
      ElMessage.warning('请登录后查看')
      return false
    }
    router.push('/Box')
    activeNav.value = i
  localStorage.setItem('navChange',i)
  }

}
const gotoPage = (url,i)=>{
    if(!localStorage.getItem('token')){
    ElMessage.warning('请登录后查看')
    return false
  }
  activeNav.value = i
  localStorage.setItem('navChange',i)
  router.push(url)
}
const gotoUser = ()=>{
  if(!localStorage.getItem('token')){
    ElMessage.warning('请登录后查看')
    return false
  }
  activeNav.value = 0
  localStorage.setItem('navChange',0)
  router.push('/User')
}
const outLogin = ()=>{
  logoutApi().then(res=>{
    localStorage.removeItem("navChange")
    localStorage.removeItem("token")
    localStorage.removeItem("name")
    router.push('/Login')
  })
}
const searchFun = ()=>{
}
//登录
const login = ()=>{
  router.push('/Login')
}
if(localStorage.getItem('token') == null){
    isLogin.value = true
  }else{
    isLogin.value = false
    userName.value = localStorage.getItem('name')
  }
</script>
<style lang="scss" scoped>
.mobelNav {
  display: none;
}
.nav {
  width: 100vw;
  height: 58px;
  background-color: #676eff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 10px 150px;
  z-index: 99;
  .logo {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
  }
  .nav_box {
    width: 12vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .nav_item {
      cursor: pointer;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      &:hover {
        color: #ffd600;
      }
    }
    .activeNav {
      color: #ffd600;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 60px;
        height: 4px;
        background-color: #ffd600;
      }
    }
  }
  .searchInput {
    width: 300px;
    height: 90%;
    margin: 0 10px 0 0;
    .inputSearch {
      width: 100%;
      height: 100%;
    }
  }
  .nav_icon {
    display: flex;
    align-items: center;
    i {
      margin: 0 6px;
      cursor: pointer;
      font-size: 24px;
      :hover {
        opacity: 0.8;
      }
    }
    .lang {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 0 5px;
      cursor: pointer;
      p {
        margin: 2px 6px 0 0;
      }
      img {
        width: 30px;
        height: 20px;
      }
    }
    .login {
    }
  }
  .sbut {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.avater {
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin: 0 0 0 6px;
  }
}
:deep(.el-input__wrapper) {
  height: 30px;
}
:deep(.el-input-group__append) {
  button {
    height: 100%;
  }
}
:deep(.el-button--large) {
  height: 90%;
  padding: 0;
}
/* 超小屏，字体黑色，背景蓝色*/
@media screen and (max-width: 480px) {
  .nav {
    // height: 90px;
    // font-size: 30px;
    // padding: 4px 30px;
  }
}
</style>
