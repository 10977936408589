<template>
  <router-view />
</template>
<script>
const debounce = (fn, delay) => {
  let timer
   return (...args) => {
     if (timer) {
       clearTimeout(timer)
     }
     timer = setTimeout(() => {
       fn(...args)
     }, delay)
   }
}
  
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
   constructor(callback) {
     callback = debounce(callback, 200);
     super(callback);
   }
}
</script>
<style>
@import url("./assets/style/mobel.scss");
</style>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #010025;
  font-size: 16px;
  min-width: 400px;
}
html {
  overflow-x: hidden;
  min-height: 100vh;
}
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-width: 400px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:deep(.el-input__inner) {
  height: 100%;
}

img {
  object-fit: fill;
}
.el-tabs__item {
  font-size: 16px;
}
.flex_column {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_bet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
