import service from "@/request/http.js";
/**
 * 用户类
 */
// 登录
export function loginApi(data) {
  return service({
    url: "/user/login",
    method: "POST",
    data,
  });
}
//注册
export function regApi(data) {
  return service({
    url: "/user/reg",
    method: "POST",
    data,
  });
}
//退出登录
export function logoutApi(data) {
  return service({
    url: "/personal/logout",
    method: "GET",
    params: data,
  });
}
//余额充值
export function balanceApi(data) {
  return service({
    url: "/personal/balance",
    method: "POST",
    data,
  });
}
//获取余额
export function getBalanceApi(data) {
  return service({
    url: "/personal/balance",
    method: "GET",
    params: data,
  });
}
//删除地址
export function deleAddressApi(id) {
  return service({
    url: `/personal/address/${id}`,
    method: "DELETE",
  });
}
//用户地址更新
export function changeAddressApi(id) {
  return service({
    url: `/personal/address/${id}`,
    method: "PUT",
  });
}
//创建地址
export function createAddressApi(data) {
  return service({
    url: `/personal/address`,
    method: "POST",
    data,
  });
}
//地址列表
export function getAddressApi(data) {
  return service({
    url: `/personal/address`,
    method: "GET",
    params: data,
  });
}
// 用户中奖记录
export function getLuckyListApi(data) {
  return service({
    url: `/personal/lucky`,
    method: "GET",
    params: data,
  });
}
/**
 * 商品类
 */

//商品列表
export function getGoods(data) {
  return service({
    url: "/goods",
    method: "GET",
    params: data,
  });
}
//商品详情
export function getGoodsDetails(id) {
  return service({
    url: `/goods/${id}`,
    method: "GET",
  });
}
//商品搜索
export function searchGoodsApi(data) {
  return service({
    url: `/goods/search`,
    method: "GET",
  });
}
//商品分类列表
export function getGoodsClass(data) {
  return service({
    url: "/goods/category",
    method: "GET",
    params: data,
  });
}
/**
 * 订单类
 */
//订单列表
export function getOrderApi(data) {
  return service({
    url: `/order`,
    method: "GET",
    params: data,
  });
}
//创建订单
export function createOrderApi(data) {
  return service({
    url: `/order`,
    method: "POST",
    data,
  });
}
//取消订单
export function cancelOrderApi(id) {
  return service({
    url: `/order/${id}/cancel`,
    method: "PUT",
  });
}
//订单支付
export function payOrderApi(id, data) {
  return service({
    url: `/order/${id}/pay`,
    method: "POST",
    data,
  });
}
/**
 * 盲盒奖卷
 */
//优惠卷
export function getCouponList(data) {
  return service({
    url: `/coupon`,
    method: "GET",
    params: data,
  });
}
//盲盒列表
export function getBoxList(data) {
  return service({
    url: `/box`,
    method: "GET",
    params: data,
  });
}
//抽取盲盒
export function drawBoxApi(data) {
  return service({
    url: `/box/draw`,
    method: "POST",
    data,
  });
}
//盲盒详情
export function editBoxApi(id) {
  return service({
    url: `/box/${id}`,
    method: "GET",
  });
}
//中奖名单
export function getBoxLuckList(data) {
  return service({
    url: `/lucky`,
    method: "GET",
    data,
  });
}
