export default {
  cy: {
    cy1: "确定",
    cy2: "取消",
    cy3: "重置",
    cy4: "提交",
    cy5: "关闭",
    cy6: "购物车",
    cy7: "收藏",
    cy8: "购买",
    cy9: "成功",
    cy10: "失败",
    cy11: "错误",
    cy12: "查看",
    cy13: "价格",
  },
};
