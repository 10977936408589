<template>
  <div class="home">
    <Nav></Nav>
    <main class="page_content">
      <div class="banner">
        <div class="box1">
          <img src="../../public/img/image/t1.jpg" alt="" />
        </div>
        <div class="flex_column box2">
          <img src="/img/image/t2.jpg" alt="" />
          <img src="/img/image/t3.jpg" alt="" />
        </div>
      </div>
      <div class="classify">
        <div class="class_item" v-for="(item, index) of classify" :key="idnex">
          <img :src="item.img" alt="" />
          <p class="classNmae">{{ item.name }}</p>
          <div class="flex c_bottom">
            <p class="prc">${{ item.per_price }}</p>
            <p class="f_btn" @click="gotoBox(item, 2)">{{ t("cy.cy12") }}</p>
          </div>
        </div>
      </div>
      <div class="notice-bar">
        <van-notice-bar left-icon="volume-o" :scrollable="false">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :touchable="false"
            :show-indicators="false"
          >
            <van-swipe-item v-for="(item, index) of noticeData" :key="index"
              >{{ item.user_name }} 抽中了 {{ item.level }} 等奖
              {{ item.prize_name }}</van-swipe-item
            >
          </van-swipe>
        </van-notice-bar>
      </div>
      <div class="shopModel">
        <div class="s_left">
          <div class="s_title">
            <p>智能手机</p>
            <p>最新、最多折扣都在这里</p>
          </div>
          <div class="s_row">
            <div
              class="s_item"
              @click="gotoBox(item, 2)"
              v-for="(item, index) of shopList"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <p style="font-weight: bold">$ {{ item.price }}</p>
              <p class="introduce">{{ item.text }}</p>
              <div class="shopFun">
                <div class="shopCar">
                  <!-- <el-icon><ShoppingCart /></el-icon> -->
                  <p>{{ t("cy.cy12") }}</p>
                </div>
                <!-- <el-icon><Star /></el-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="s_right">
          <img src="/img/testImage/phone4.png" alt="" />
        </div>
      </div>
      <div class="shopModel">
        <div class="s_right">
          <img src="/img/testImage/key4.png" alt="" />
        </div>
        <div class="s_left">
          <div class="s_title">
            <p>智能配件</p>
            <p>最新、最多折扣都在这里</p>
          </div>
          <div class="s_row">
            <div
              class="s_item"
              @click="gotoBox(item, 2)"
              v-for="(item, index) of shopList2"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <p style="font-weight: bold">$ {{ item.price }}</p>
              <p class="introduce">{{ item.text }}</p>
              <div class="shopFun">
                <div class="shopCar">
                  <!-- <el-icon><ShoppingCart /></el-icon> -->
                  <p>{{ t("cy.cy12") }}</p>
                </div>
                <!-- <el-icon><Star /></el-icon> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shopModel">
        <div class="s_left">
          <div class="s_title">
            <p>数码相机</p>
            <p>最新、最多折扣都在这里</p>
          </div>
          <div class="s_row">
            <div
              class="s_item"
              @click="gotoBox(item, 2)"
              v-for="(item, index) of shopList3"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <p style="font-weight: bold">$ {{ item.price }}</p>
              <p class="introduce">{{ item.text }}</p>
              <div class="shopFun">
                <div class="shopCar">
                  <!-- <el-icon><ShoppingCart /></el-icon> -->
                  <p>{{ t("cy.cy12") }}</p>
                </div>
                <!-- <el-icon><Star /></el-icon> -->
              </div>
            </div>
          </div>
        </div>
        <div class="s_right">
          <img src="/img/testImage/xj.png" alt="" />
        </div>
      </div>
      <!-- filter -->
      <div class="filter">
        <div class="filter_fun">
          <h2>分类</h2>
          <el-radio-group
            @change="radioChange"
            v-model="FilterVal"
            class="ml-4"
          >
            <el-radio
              :value="item.id"
              size="large"
              v-for="(item, index) of filterData"
              :key="index"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
          <!-- <div class="fliter_check">
            <div class="checkBox">
              <p class="lable_n">Gerder</p>
              <el-checkbox v-model="checked3" label="Male" />
              <el-checkbox v-model="checked4" label="Female" />
            </div>
            <div class="checkBox">
              <p class="lable_n">Age group</p>
              <el-checkbox v-model="checked3" label="Adoult" />
              <el-checkbox v-model="checked4" label="Children" />
            </div>
            <div class="checkBox">
              <p class="lable_n">类别三</p>
              <el-checkbox v-model="checked3" label="Option 1" />
            </div>
            <div class="checkBox">
              <p class="lable_n">类别三</p>
              <el-checkbox v-model="checked3" label="Option 1" />
            </div>
            <div class="checkBox">
              <p class="lable_n">类别三</p>
              <el-checkbox v-model="checked3" label="Option 1" />
            </div>
            <div class="checkBox">
              <p class="lable_n">Price</p>
              <div class="flex_column">
                <el-input-number
                  v-model="minNum"
                  class="mx-4"
                  :min="1"
                  placeholder="MIN"
                  controls-position="right"
                  @change="handleChange1"
                />
                <el-input-number
                  v-model="maxNum"
                  class="mx-4"
                  :min="1"
                  placeholder="MAX"
                  controls-position="right"
                  @change="handleChange2"
                />
              </div>
            </div>
          </div> -->
        </div>
        <div class="fliter_box">
          <div class="f_shopList">
            <div
              class="f_shopItem"
              v-for="(item, index) of shopListData"
              :key="index"
              @click="getShopInfo(item)"
            >
              <img v-if="item.img" :src="item.img" alt="" />
              <img v-else src="/img/image/404.svg" alt="" />
              <p style="margin: 5px 0">{{ item.name }}</p>
              <div class="flex_bet">
                <p style="color: #f2683c; font-weight: bold">
                  $ {{ item.price }}
                </p>
                <div class="felx">
                  <div class="shopCar">
                    <!-- <el-icon><ShoppingCart /></el-icon> -->
                    <p>购买</p>
                  </div>
                </div>
              </div>
              <p class="introduce">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
    <Footer></Footer>
  </div>
</template>

<script setup lang="js">
import {ref,reactive,onMounted}from "vue"
import { ElMessage  } from "element-plus";
import { Star,ShoppingCart,Message } from '@element-plus/icons-vue';
import Nav from "../components/Nav.vue"
import Footer from "../components/Footer.vue"
import {getGoodsClass,getGoods,getBoxLuckList,getBoxList} from "../request/api"
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const router = useRouter()
const minNum = ref('')
const maxNum = ref('')
const handleChange1 = ()=>{
  console.log(minNum.value);
}
const handleChange2 = ()=>{
  console.log(maxNum.value);
}
onMounted(()=>{
  getData()
})
const classId = ref(1)
const pageSet = ref({
  page:1,
  page_size:20,
  total:0
})
const shopListData = ref([])
const filterData = ref([])
const radioChange = (e)=>{
  getGoods({
    page: pageSet.value.page,
    page_size: pageSet.value.page_size,
    cid:e,
    cname:""
  }).then(res=>{

    shopListData.value =res.data.goods
  }).catch(err=>{
    console.log(err);
  })
}
// 滚动消息
const noticeData = ref([])
const getBoxOrder = ()=>{
  getBoxLuckList({}).then(res=>{
    noticeData.value = res.data
  }).catch(err=>{
    console.log(err);
  })
}
getBoxOrder()
const getBoxLists = ()=>{
  getBoxList({
    page:pageSet.value.page,
    page_size:pageSet.value.page_size,
  }).then(res=>{
    classify.value = res.data.boxes
  }).catch(err=>{
    console.log(err);
  })
}
getBoxLists()
const FilterVal=ref(1)
const getData = ()=>{
  getGoodsClass().then(res=>{
    filterData.value = res.data.goods
  }).catch(err=>{
    console.log(err);
  })
  getGoods({
    page: pageSet.value.page,
    page_size: pageSet.value.page_size,
    cid:classId.value,
    cname:""
  }).then(res=>{

    shopListData.value =res.data.goods
  }).catch(err=>{
    console.log(err);
  })
}
//跳转详情
const getShopInfo = (item)=>{
  if(!localStorage.getItem('token')){
    ElMessage.warning('请登录后查看')
    return false
  }
  console.log(item);
  router.push({ path: '/Details', query: { id: item.id }})
}
//跳转盲盒
const gotoBox = (item,i)=>{
  if(!localStorage.getItem('token')){
    ElMessage.warning('请登录后查看')
    return false
  }
  localStorage.setItem('navChange',i)
  router.push({ path: '/Box', query: { id: item.id }})
}
//假数据
const classify = ref([
  {
  img:'/img/image/book.png',
  name:'电脑平板',
  price:"1999",
  original:"2599"
},
{
  img:'/img/image/key1.png',
  name:'智能配件',
  price:"1999",
  original:"2599"
},
{
  img:'/img/image/wacth.png',
  name:'智能手表',
  price:"1999",
  original:"2599"
},
{
  img:'/img/image/xyj.png',
  name:'智能家电',
  price:"1999",
  original:"2599"
},
{
  img:'/img/image/phone1.png',
  name:'智能手机',
  price:"1999",
  original:"2599"
},
{
  img:'/img/image/xj.png',
  name:'数码相机',
  price:"1999",
  original:"2599"
},
])
const shopList = ref([
  {
    img:"/img/image/phone1.png",
    price:"5999",
    text:"iPhone 14 pro 512G"
  },
  {
    img:"/img/testImage/phone2.png",
    price:"6999",
    text:"iPhone 14 pro 512G"
  },
  {
    img:"/img/testImage/phone3.png",
    price:"2999",
    text:"iPhone 13 pro 512G"
  },
])
const shopList2 = ref([
  {
    img:"/img/testImage/key1.png",
    price:"199",
    text:"狼蛛F87 pro"
  },
  {
    img:"/img/testImage/key2.png",
    price:"79",
    text:"智能充电宝"
  },
  {
    img:"/img/testImage/key3.png",
    price:"129",
    text:"智能蓝牙耳机"
  },
])
const shopList3 = ref([
  {
    img:"/img/testImage/xj1.png",
    price:"399",
    text:"尼康"
  },
  {
    img:"/img/testImage/xj2.png",
    price:"349",
    text:"富士"
  },
  {
    img:"/img/testImage/xj3.png",
    price:"1999",
    text:"佳能"
  },
])
</script>
<style lang="scss" scoped>
@import url("../assets/style/index.scss");
</style>
<style>
.el-radio-group {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.el-radio {
  margin: 0;
}
</style>
