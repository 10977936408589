import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/box",
    name: "box",
    component: () => import("../views/Box.vue"),
  },
  {
    path: "/User",
    name: "User",
    component: () => import("../views/User.vue"),
  },
  {
    path: "/Collect",
    name: "Collect",
    component: () => import("../views/Collect.vue"),
  },
  {
    path: "/ShoppingCart",
    name: "ShoppingCart",
    component: () => import("../views/ShoppingCart.vue"),
  },
  {
    path: "/Details",
    name: "Details",
    component: () => import("../views/Details.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  next();
  window.scrollTo(0, 0);
});

export default router;
